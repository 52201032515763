<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
      >&#8203;

      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
        role="dialog"
      >
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              id="modal-headline"
              class="text-lg leading-6 font-medium text-gray-900"
            >
              {{ $t("revoke-consent") }}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {{
                  $t(
                    "are-you-sure-you-want-to-revoke-consents-to-all-documents"
                  )
                }}
              </p>
              <p
                v-if="get_profile.revoke_access_on_consent_disagree"
                class="text-sm leading-5 text-gray-500"
              >
                {{
                  $t(
                    "you-wont-be-able-to-use-the-application-until-consent-is-given"
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-2"
          >
            <button
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="$emit('close')"
            >
              {{ $t("No") }}
            </button>
          </span>
          <span
            v-if="get_profile.revoke_access_on_consent_disagree"
            class="flex w-full rounded-md shadow-sm sm:col-start-1"
          >
            <button
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="logout"
            >
              {{ $t("yes-and-logout") }}
            </button>
          </span>
          <span v-else class="flex w-full rounded-md shadow-sm sm:col-start-1">
            <button
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              type="button"
              @click="revokeConsent"
            >
              {{ $t("Yes") }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "RevokeConsent",
  computed: {
    ...mapGetters(["get_profile"]),
  },
  created() {
    this.$store.dispatch("profile");
  },
  methods: {
    revokeConsent() {
      this.$store.dispatch("revoke");
      this.$store.dispatch("user_data");
      this.$emit("close");
    },
    logout() {
      this.$store.dispatch("revoke");
      this.$store.dispatch("logout").then(() => {
        if (this.whitelabel === "VCGMarkets") {
          //   redirect to external login
          const url = process.env.VUE_APP_API_URL.includes("dev.")
            ? "https://dev-website.anttix.app/en/auth/login"
            : "https://vcgmarkets.com/en/auth/login";
          return window.open(url, "_self");
        }
        this.$router.push("/auth/login");
      });
    },
  },
};
</script>
